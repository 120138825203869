/* .Principal {
	display: grid;
	grid-template-columns: 5% 10% 85%;
	grid-template-rows: 100%;
} */

.SwitchSecundario {
	grid-column: 1/4;
	grid-row: 1/2;
	z-index: 0;
}

/* Spinner Begin */

.Spinner {
	display: grid;
	grid-template-columns: repeat(8, auto);
	grid-template-rows: repeat(8, auto);
}

.SpinnerBase {
	grid-column: 1/9;
	grid-row: 1/9;
	z-index: 0;
}

.SpinnerCharge {
	background-color: #979fc58e;
	height: 100%;
	width: 100%;
	grid-column: 1/9;
	grid-row: 1/9;
	z-index: 99;
	display: grid;
	justify-content: center;
}

.SpinnerChargeSub {
	height: 100vh !important;
	bottom: 0;
	left: 0;
	right: 0;
	top: 0;
	justify-content: center !important;
}

.SpinnerChargeSub div {
	position: fixed;
}

/* NAVBAR */

.PaginaActual {
	background-color: rgb(146, 203, 236) !important;
}

.NavbarCortito {
	grid-column: 1/2;
	grid-row: 1/2;
	z-index: 8;
	font-size: 33px;
	text-align: left;
	color: grey;
}

.NavbarCortito button {
	cursor: pointer;
}

.Submarino {
	font-size: 33px !important;
	text-align: center !important;
	color: grey !important;
}

.Submarino:hover {
	cursor: pointer;
}

.isDisabled {
	color: currentColor;
	cursor: not-allowed;
	opacity: 0.5;
	text-decoration: none;
}

  /* HEAD */

  .mesFiestasPatrias{
	background-image: url("/src/img/guirnalda.png");
	background-size: cover;
	background-position: left;
	background-repeat: repeat-x;
	background-size: 400px 40px;
	margin-top: 0px !important;
	padding-top: 0px !important;
	margin-bottom: 20px;
	padding-left: -50px;
	padding-right: -50px;
	margin-right: -50px;
	margin-left: -50px;
  }

  .mesNavidad{
	background-image: url("/src/img/navidad.png");
	background-size: cover;
	background-position: left;
	background-repeat: repeat-x;
	background-size: 600px 60px;
	margin-top: 0px !important;
	padding-top: 0px !important;
	margin-bottom: 20px;
	padding-left: -50px;
	padding-right: -50px;
	margin-right: -50px;
	margin-left: -50px;
  }
