/* CALENDARIO */
.react-calendar {
    padding: 10px !important;
    padding-top: 30px !important;
    padding-bottom: 50px !important;
    width: 700px !important;
    background: white;
    font-family: Arial, Helvetica, sans-serif;
    font-size: medium !important;
    line-height: 1.125em !important;
    cursor: default !important;
}

.react-calendar * {
    cursor: default !important;
}

.react-calendar *:hover {
    background-color: white !important;
}

.cumple {
    height: 30px !important;
    width: 30px !important;
    background: #ffffff !important;
    border-radius: 50% !important;
    display: inline-block;
    cursor: pointer !important;
}

.cumple abbr {
    display: none;
}

.cumple::after {
    content: '🎈';
}

button.react-calendar__tile.react-calendar__month-view__days__day {
    overflow: visible !important;
}

.cumple * {
    cursor: pointer !important;
}

.cumple:hover {
    background-color: #ffffff !important;
}

.cumple abbr:active {
    background-color: #ffffff !important;
}

.cumple abbr:focus {
    background-color: #ffffff !important;
}

.cumple abbr:hover {
    background-color: #ffffff !important;
}

.react-calendar__tile--now {
    background-color: rgb(21, 40, 246) !important;
    border-radius: 50% !important;
}

.react-calendar__tile--active {
    background-color: rgb(21, 40, 246) !important;
    border-radius: 50% !important;
}

.react-calendar__navigation__arrow {
    background-color: white !important;
}

.react-calendar__navigation__label__labelText {
    font-weight: bolder;
}

.feriado {
    height: 30px !important;
    width: 30px !important;
    background: #e8f46846 !important;
    border-radius: 50% !important;
    display: inline-block;
}

.feriado abbr {
    display: none;
}

.feriado::after {
    content: '🎈';
}

/* Cumpleaños CSS */
.suph1 {
    font-family: "Oswald", sans-seri !important;
    justify-content: center !important;
    align-items: center !important;
    font-size: 1.15vmin !important;
}

.h1 {
    font-family: "Oswald", sans-serif !important;
    font-size: 9em !important;
    background-image: url("../img/cumpleanos.png");
    color: #fff !important;
    color: transparent !important;
    background-size: contain !important;
    -webkit-background-clip: text !important;
    filter: drop-shadow(0px -3px 1px rgba(0, 0, 0, 1)) !important;
    animation: stripes 5s infinite alternate !important;
}

@keyframes stripes {
    100% {
        background-position: 100px 0, 100px 0, 100px 0;
    }
}