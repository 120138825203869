.ImagenCedula {
	text-align: center;
	margin: 0 auto;
	padding-bottom: 10px;
}

.Sube-Cedula {
	background-color: rgb(240, 229, 229);
	margin-top: 10px;
	margin-bottom: 10px;
	padding: 10px;
}

.divGenerador{
	transition-property: all;
    transition-duration: 300ms;
    transition-timing-function: ease-in;
    opacity: 0;
}

.divGenerador.animar {
    position: relative;
    opacity: 1;
}

.divGenerador.noAnimar {
    position: relative;
    opacity: 0;
}
