.modal {
    position: absolute;
    background-color: rgb(0 0 0 / 0.92);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99999 !important;
    height: 100% !important;
}
.modal .cerrar-modal {
    position: absolute;
    right: 3rem;
    top: 3rem;
    width: 3rem;
    height: 3rem;
}

.modal .cerrar-modal img {
    width: 100%;
}

.modal .formulario {
    /* max-width: 80rem; */
    /* width: 40rem; */
    padding-top: 50px;
    transition-property: all;
    transition-duration: 300ms;
    transition-timing-function: ease-in;
    opacity: 0;
}
.modal label {
    color: white !important;
}
body .modal {
    overflow: hidden;
}

.modal .formulario.animar {
    position: relative;
    opacity: 1;
}

.modal .formulario.cerrar {
    opacity: 0;
}

.modal label {
    text-align: left;
}