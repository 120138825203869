.filtroGrid {
	display: grid;
	grid-template-columns: repeat(5, auto);
	column-gap: 5px;
	grid-template-rows: auto;
}

.alertFiltro {
	grid-column: 1/5;
	margin: 0 auto;
}
