/* Animaciones */

.divGenerador{
	transition-property: all;
    transition-duration: 300ms;
    transition-timing-function: ease-in;
    opacity: 0;
}

.divGenerador.animar {
    position: relative;
    opacity: 1;
}

.divGenerador.noAnimar {
    position: relative;
    opacity: 0;
}

/* Info antes de Animaciones */


.print-yes {
	display: none;
}

.DivPrincipal {
	display: grid;
	grid-template-columns: 25% 25% 25% 25%;
	grid-template-rows: 5vh 22vh 8vh 20vh 19vh 7vh;
}

.DivTitulo {
	grid-column: 1/4;
	grid-row: 1/2;
}

.DivFiltro {
	grid-column: 4/5;
	grid-row: 1/2;
}

.FiltroDetallePago {
	grid-column: 1/5;
	grid-row: 2/3;
}

.DivSecundario {
	grid-column: 1/5;
	grid-row: 2/6;
	z-index: 0;
	overflow-y: scroll;
}

.DivSecundario2 {
	grid-column: 1/5;
	grid-row: 3/6;
	z-index: 0;
	overflow-y: scroll;
}

.DivTerciario {
	grid-column: 2/4;
	grid-row: 1/4;
	z-index: 8;
	filter: drop-shadow(0px 4px 8px #000000);
}

.DivBotones {
	grid-column: 1/5;
	grid-row: 6/7;
	margin-top: 1vh;
}

.notas {
	height: 200px;
	overflow-y: scroll;
	display: flex;
	flex-direction: column-reverse;
}

/* Paginator */
.pagination {
	display: inline-block;
}

.pagination a {
	color: black;
	float: left;
	padding: 8px 16px;
	text-decoration: none;
}

.sell {
	background-color: rgba(23, 121, 186, 255) !important;
	color: white !important;
}

@media print {
	.no-print,
	.no-print * {
		display: none !important;
	}

	.yes-print {
		transform: translate(-240px, 0px) !important;
		width: 130%;
	}

	.print-yes {
		display: inline;
	}
}
