.dropbtnVentas {
    background-color: #043eaa5e;
    color: white;
    padding: 16px;
    font-size: 20px !important;
    border: none;
  }
  
  .dropdownVentas {
    position: relative;
    display: inline-block;
  }
  
  .dropdown-contentVentas {
    display: none;
    position: absolute;
    background-color: #f1f1f1;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 99999;
  }
  
  .dropdown-contentVentas a {
    font-size: 16px !important;
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
  }
  
  .dropdown-contentVentas a:hover {background-color: #ddd;}
  
  .dropdownVentas:hover .dropdown-contentVentas {display: block;}
  
  .dropdownVentas:hover .dropbtnVentas {background-color: #9d9d9d;}